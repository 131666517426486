<template>
    <div class="card"  :class="{notActive: !active}">
        <div class="card__goal" v-if="active">
            {{ props.msg }}?
        </div>
        <div class="card-btn" v-if="active"><button class="card-btn--yes" @click="change(1)">Да</button></div>
        <div class="card-btn" v-if="active"><button class="card-btn--no" @click="change(0)">Нет</button></div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
    id: {
        type: Number,
        required: false,
        default: 0
    },
    msg: {
        type: String,
        required: false,
        default: ""
    },
    active: {
        type: Boolean,
        required: false,
        default: false
    }
});

const emit = defineEmits(['change']);

function change(ans) {
    emit('change', props.id, ans, props.msg);
}

</script>

<style lang="scss" scoped>
// @keyframes show {
//   from { transform: rotateY(-90deg); }
//   to { transform: rotateY(0deg); }
// }

// .show {
//     animation: 0.1s show ease;
// }

// .hide {
//     transform: rotateY(90deg);
// }

.card {
    width: 12rem;
    height: min(15rem, 100vh - 11rem);
    background-color: #51c9fcc6;
    padding: 0.5rem;
    color: white;
    font-family: 'Sansation', sans-serif;
    display: grid;    
    grid-template-rows: 85% 15%;
    grid-template-columns: 50% 50%;

    &__goal {
        margin: auto 0;
        text-align: center;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    @media only screen and (max-width: $bp-large) { 
        width: 10rem;
    }

    @media only screen and (max-width: $bp-medium) { 
        width: 8rem;
    }

    @media only screen and (max-width: $bp-small) { 
        width: min(80vw, 25rem);
        height: min(6rem, calc((100vh - 11rem) / 4));
        grid-template-rows: 100%;
        grid-template-columns: auto 2fr auto;
        padding: 0;

        &__goal {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
        }
    }
}

.card-btn {
    display: flex;
    justify-content: center;

    &--yes {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }

    &--no {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
    }
    
    button {
        padding: 5px 10px;
        border: none;
        color: white;
        background-color: transparent;
        background-repeat: no-repeat;
        cursor: pointer;
        outline: none;

        &:hover {
            background-color: #ffffff2b;
        }

        &:active {
            color: #ececec;
            background-color: #0000002b;
        }
    }

    @media only screen and (max-width: $bp-small) { 
        height: min-content;
        align-self: center;
        height: 100%;

        &--yes {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        &--no {
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
        }
    }
}

.notActive {
    background-color: #3f8aaac6;
}
</style>