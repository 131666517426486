<template>
    <div id="header">
        <div id="header__title">
            <h2>My</h2>
            <h1>2023</h1>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#header {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    justify-content: center;

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: min-content;
        height: min-content;

        h2 {
            font-weight: lighter;
            font-size: 26px;
            font-family: 'Sansation Regular', sans-serif;
            color: #7B591E;
        }
        h1 {
            font-size: 60px;
            font-family: 'Smooch Regular', sans-serif;
            color: #F1BA4E;
        }
    }

}
</style>