<template>
    <BaseDialog>
        <h2>С наступающим Новым Годом! 🎉🎉🎉</h2>
        <br />
        <p>Как быстро летит время! Уже скоро наступит 2024 год. Давайте вспомним, чего Вы достигли за этот год!</p>
        <br />
        <p>Для этого я могу предложить Вам ответить на 40 вопросов на карточках, которые помогут вспомнить достижения за прошедший год. После ответа на вопрос карточка поменяет его. Если никакой вопрос не подходит Вам, Вы можете обновить весь набор карточек. (Для этого в левом нижнем углу есть специальная кнопочка)</p>
        <br />
        <p>По итогу Вы составите уникальный набор карточек. Не забудьте поделиться результатом в социальных сетях! 🎉🎉🎉</p>
    </BaseDialog>
    <div id="body">
        <div class="content">
            <GoalCard v-if="cards[0]" :id="0" :msg="cards[0].goal" :active="cards[0].active" @change="change" />
            <GoalCard v-if="cards[1]" :id="1" :msg="cards[1].goal" :active="cards[1].active" @change="change"/>
            <GoalCard v-if="cards[2]" :id="2" :msg="cards[2].goal" :active="cards[2].active" @change="change"/>
            <GoalCard v-if="cards[3]" :id="3" :msg="cards[3].goal" :active="cards[3].active" @change="change"/>
        </div>
    </div>
    <div id="count">{{ result.length }} / 40</div>
    <div id="update"><button @click="update()">новый набор</button></div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex'
import GoalCard from './GoalCard.vue';
import BaseDialog from './BaseDialog.vue';

const store = useStore()

var goals = computed(() => store.getters.getGoals);
var result = computed(() => store.getters.getResult);

var cards = ref([]);

watch(goals, () => {
    shuffle(goals.value);
    update();
});

function update() {
    if (44 - result.value.length > goals.value.length) {
        return;
    }

    for (let i = 0; i < 4; ++i) {
        cards.value[i] = { 
            goal: goals.value.shift(),
            active: true
        };
    }
}

function change(id, ans, msg) {
    cards.value[id] = { 
        active: goals.value.length > 0 ? true : false,
        goal: goals.value.shift()
    };
    store.dispatch('addGoalToResult', { ans: ans, goal: msg });
}

function shuffle(array) {
  var currentIndex = array.length, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    --currentIndex;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

</script>

<style lang="scss" scoped>
#count {
    display: inline-block;
    position: absolute;
    top: 100vh;
    right: 10px;
    transform: translateY(calc(-100%));
    font-family: 'Sansation', sans-serif;
    color: #7B591E;
}

#update {
    display: inline-block;
    position: absolute;
    top: 100vh;
    left: 10px;
    transform: translateY(calc(-100%));
    font-family: 'Sansation', sans-serif;
    
    button {
        padding: 7px 5px;
        font-size: 15px;
        color: #7B591E;
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        cursor: pointer;
        outline: none;

        &:hover {
            background-color: #1bd5e222;
            color: #816940;
        }

        &:active {
            background-color: #1bd5e25b;
            color: #99886a;
        }
    }
}

a {
    color: #4db2ec;

    &:hover {
        color: #3e8fbe;
    }

    &:active {
        color: #285e7d;
    }
}

.content {
    padding: 0 10%;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;

    @media only screen and (max-width: $bp-large) { 
        padding: 0 5%;
    }

    @media only screen and (max-width: $bp-medium) { 
        padding: 0;
    }

    @media only screen and (max-width: $bp-small) { 
        flex-direction: column;
    }
}

#body {
    width: 100%;
    height: 100%;
}
</style>