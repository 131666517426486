export default {
    setGoals(context) {
        import("@/assets/goals.json").then((goalsModule) => {
        const goals = goalsModule.default;
        
        context.commit('setGoals', goals.goals);
        }).catch((error) => {
            console.error("Ошибка при импорте goals.json: ", error);
        });
    },
    addGoalToResult(context, payload) {
        context.commit('addGoal', payload);
    }
}