<template>
  <main-header v-if="!showResult"/>
  <main-body v-if="!showResult"/>
  <div class="content" v-else>
    <MainResult :goals="result" />
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import MainBody from './components/MainBody.vue';
import MainResult from './components/MainResult.vue';

export default {
  name: 'App',
  components: {
    MainHeader,
    MainBody,
    MainResult
  },
  created() {
    this.$store.dispatch('setGoals');
  },
  computed: {
    result() {
      return this.$store.getters["getResult"];
    },
    showResult() {
      return this.$store.getters["getResult"].length >= 40;
    }
  }
}
</script>

<style lang="scss">
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  background-image: url(../public/img/snowflakes-background.png);
}

#app {
  height: 100vh;
  width: 100vw;

  display: grid;
  grid-template-rows: [header-start]9rem [header-end body-start]calc(100vh - 10rem) [body-end footer-start] 1rem [footer-end];
}

.content {
  width: 100vw;
  height: 100vh;

  grid-row: 1 / span 3;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
