import { createStore } from 'vuex';

import GoalsModule from './modules/goals/index.js';

const store = createStore({
  modules: {
    GoalsModule
  },
});

export default store;