<template>
    <div class="result">
        <img class="result__img" :src="imgSrc" />
    </div>
    <div class="share" @click="share()">
        <img class="share__icon" src="/img/share.svg" />
        <p>поделиться</p>
    </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
    goals: {
        type: Array,
        required: true
    },
});

import { ref, onMounted } from 'vue'

const imgSrc = ref('');
let ctx;

function drawText(context, text, position, maxWidth, lineHeight) {
    var words = text.split(' ');
    var line = '';

    for(var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = context.measureText(testLine);
        if (metrics.width > maxWidth && n > 0) {
            context.fillText(line, position.X, position.Y);
            line = words[n] + ' ';
            position.Y += lineHeight;
        }
        else {
            line = testLine;
        }
    }
    context.fillText(line, position.X, position.Y);
}

function drawAns(ans, position) {
    ctx.beginPath();
    ctx.arc(position.X - 20, position.Y - 10, 10, 0, 2 * Math.PI);
    ctx.fillStyle = "#CFCFCF"; 
    if (ans == true) {
        ctx.fillStyle = "#bc2c2c";
    }
    ctx.fill();

    ctx.beginPath();
    ctx.arc(position.X - 20, position.Y - 10, 5, 0, 2 * Math.PI);
    ctx.fillStyle = "#eaeaea"; 
    if (ans == true) {
        ctx.fillStyle = "#ed6d6d";
    }
    ctx.fill();
}

onMounted(() => {
    const myFont1 = new FontFace('Sansation Regular', 'url(/fonts/Sansation-Regular.woff2)', {
        style: 'normal', unicodeRange: 'U+000-5FF', weight: '400'
    });

    const myFont2 = new FontFace('Smooch', 'url(/fonts/Smooch-Regular.woff2)', {
        style: 'normal', unicodeRange: 'U+000-5FF', weight: '400'
    });

    const myFont3 = new FontFace('Sansation Bold', 'url(/fonts/Sansation-Bold.ttf)', {
        style: 'normal', unicodeRange: 'U+000-5FF', weight: '400'
    });

    const backgroundImage = new Image();
    backgroundImage.src = "/img/snowflakes-background.png";

    const canvas = document.createElement('canvas');
    canvas.width = 1700;
    canvas.height = 2200;

    ctx = canvas.getContext('2d');

    Promise.all([
        new Promise((resolve, reject) => {
            backgroundImage.onload = () => resolve();
            backgroundImage.onerror = reject;
        }),
        myFont1.load(),
        myFont2.load(),
        myFont3.load()
    ]).then(() => {
        document.fonts.add(myFont1);
        document.fonts.add(myFont2);
        document.fonts.add(myFont3);
        
        for(let x = 0; x < canvas.width; x += backgroundImage.width) {
            for(let y = 0; y < canvas.height; y += backgroundImage.height) {
                ctx.drawImage(backgroundImage, x, y, backgroundImage.width, backgroundImage.height);
            }
        }

        ctx.font = '80px Sansation Regular';
        ctx.fillStyle = '#7B591E';
        ctx.textAlign = 'center';
        ctx.fillText('My', canvas.width / 2, 100);

        ctx.font = '200px Smooch';
        ctx.fillStyle = '#F1BA4E';
        ctx.fillText('2023', canvas.width / 2, 250);

        ctx.font = '30px Sansation Bold';
        ctx.textAlign = 'left';

        let Newline = {X: 100, Y: 400};

        for (let i = 0; i < props.goals.length / 2; ++i) {
            drawAns(props.goals[i].ans, Newline);
            ctx.fillStyle = "#0C0C0C";
            drawText(ctx, props.goals[i].goal, Newline, 2 * canvas.width / 5, 30)
            Newline.Y += 80;
        }

        Newline = {X: canvas.width / 2 + 100, Y: 400};

        for (let i = props.goals.length / 2; i < props.goals.length; ++i) {
            drawAns(props.goals[i].ans, Newline);
            ctx.fillStyle = "#0C0C0C";
            drawText(ctx, props.goals[i].goal, Newline, 2 * canvas.width / 5, 30)
            Newline.Y += 80;
        }

        ctx.font = '100px Smooch';
        ctx.fillStyle = '#F1BA4E';
        ctx.textAlign = 'center';
        ctx.fillText('my2023.site', canvas.width / 2, canvas.height * 0.98);

        imgSrc.value = canvas.toDataURL();
    }).catch(err => {
        console.log('Failed to load resource: ' + err);
    });
});

async function share() {

    const base64url = imgSrc.value;
    const blob = await (await fetch(base64url)).blob();
    const file = new File([blob], 'My2023.png', { type: blob.type });

    if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
            files: [file],
            url: "https://my2023.site"
        });
    }
    else {
        await navigator.clipboard.write([
            new ClipboardItem({
                'image/png': blob
            })
        ]).then(() => alert("Изображение скопировано!"));
    }
}

</script>

<style lang="scss">
.share {
    position: absolute;
    top: calc(100vh - 0.5rem);
    transform: translateY(calc(-100% - 5px));
    left: 0.1rem;
    height: 30px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-weight: lighter;
        font-size: 15px;
        font-family: 'Sansation Regular', sans-serif;
        color: #221908;
    }

    &__icon {
        height: 20px;
        width: 20px;
        
    }
    &:hover {
        &__icon {
            height: 17px;
            width: 17px;
        }
        p {
            font-size: 14px;
        }
    }
    &:active {
        background-color: #a4a4a418;

        &__icon {
            height: 15px;
            width: 15px;
        }
    }
}

.result {
    height: 95%;
    max-width: 95vw;  
    padding: 1rem;

    @media only screen and (max-width: $bp-small) { 
        height: min-content;
    }

    &__img {
        max-width: 80vw;  
        max-height: 100%;  
        border: 7px solid #F1BA4E;;
    }
}
</style>